<template>
  <div v-if="value && value.id">
    <b-form-group
      v-if="dimension"
      label="Основные габариты ТС"
    >
      {{ dimension.name }} {{ numberRUFormat(Math.round(dimension.percentage/100*price)) }} руб.
    </b-form-group>
    <b-form-group
      v-if="optionalDimensions && optionalDimensions.length > 0"
      label="Дополнительные габариты ТС"
    >
      <b-form-checkbox-group
        id="checkbox-group-2"
        v-model="selectedOptionalDimensionsIds"
        stacked
        @change="emitValue()"
      >
        <span
          v-for="optionalDimension in optionalDimensions"
          :key="optionalDimension.id"
          :value="optionalDimension.id"
        >
          <span>{{ optionalDimension.name }}</span>&nbsp;
          <span>{{ numberRUFormat(Math.round(optionalDimension.percentage/100*price)) }} руб.</span><br>
        </span>
      </b-form-checkbox-group>
    </b-form-group>
  </div>
</template>

<script>
import {numberToRUFormat} from '@/components/mixins/helpers';

export default {
  name: 'DimensionsEdit',
  props: ['value', 'price'],
  data() {
    return {
      dimension: null,
      optionalDimensions: [],
      selectedOptionalDimensionsIds: [],
    };
  },
  computed: {
    selectedDimension() {
      return {...this.value, ...{dimension: this.dimension,
        optionalDimensions: this.optionalDimensions.map((dimension) => {
          return {dimension: dimension, active: this.selectedOptionalDimensionsIds.includes(dimension.id)};
        }),
      }};
    },
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.dimension = this.value.dimension;
          if (this.value.optionalDimensions && this.value.optionalDimensions.length > 0) {
            this.selectedOptionalDimensionsIds = [];
            this.optionalDimensions = [];
            this.value.optionalDimensions.forEach((dimension) => {
              if (dimension.active) {
                this.optionalDimensions.push(dimension.dimension);
                return this.selectedOptionalDimensionsIds.push(dimension.dimension.id);
              }
            });
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    emitValue() {
      this.$emit('input', {...JSON.parse(JSON.stringify(this.selectedDimension))});
    },
    numberRUFormat(value) {
      return numberToRUFormat(value);
    },
  },
};
</script>

<style scoped>

</style>
