<template>
	<div class="animated fadeIn">
		<div>
			<h4 class="mb-3">
				Аукцион <span v-if="data.name">{{ data.name }}</span>
				<span v-if="data.status === 1" class="text-primary">
					(Ожидает начала)</span
				>
				<span v-else-if="data.status === 2" class="text-success">
					(Прием ставок)</span
				>
				<span
					v-else-if="
						data.status === 4 &&
							(data.smallestContractorBid.winner ||
								data.contractorOffer.winner)
					"
					class="text-success"
				>
					(Есть победитель)</span
				>
				<span
					v-else-if="data.status === 4 || data.status === 3"
					class="text-warning"
				>
					(Подведение итогов)</span
				>
				<span v-else-if="data.status === 6" class="text-secondary">
					(Утвержден)</span
				>
			</h4>
			<h6>
				<span v-if="data.firstBidWin" class="text-primary"
					>Розыгрыш по первой ставке</span
				>
				<span v-else>Стандартный</span>
			</h6>
			<b-row>
				<b-col xl="4">
					<div
						v-for="(playedFlight, index) in data.playedFlights"
						:key="'played-route-' + index"
						class="custom-fieldset route-and-flight-auction-details__container"
					>
						<h5 class="custom-fieldset__title">
							<i class="fa fa-map-o fa-lg mr-2" />Маршрут и детали
							рейса {{ data.playedFlights[index].routeCode }}
						</h5>
						<div
							v-if="data.playedFlights[index]"
							class="route-and-flight-auction-details"
						>
							<div
								v-if="data.playedFlights[index].points"
								class="route-and-flight-auction-details__route-container"
							>
								<h6
									class="route-and-flight-auction-details__route-container-title
                    route-and-flight-auction-details__title
                    custom-fieldset__title"
								>
									Маршрут
								</h6>
								<p
									class="route-and-flight-auction-details__route-container-desc"
								>
									В порядке следования рейса
								</p>
								<div
									v-if="
										data.playedFlights[index].points
											.length > 0
									"
									class="route-auction"
								>
									<div
										v-for="point in data.playedFlights[
											index
										].points"
										:key="'point-' + point.rowNumber"
										class="route-auction__item"
									>
										<div class="route-auction__item-header">
											<div
												class="route-auction__item-number"
											>
												Пункт №{{ point.rowNumber }}
											</div>
											<button
												v-if="point.rowNumber === 1"
												class="route-auction__item-type btn btn-square btn-block btn-secondary"
												disabled
											>
												Загрузка
											</button>
											<button
												v-else-if="
													point.rowNumber ===
														data.playedFlights[
															index
														].length
												"
												class="route-auction__item-type btn btn-square btn-block btn-secondary"
												disabled
											>
												Разгрузка
											</button>
											<button
												v-else
												class="route-auction__item-type btn btn-square btn-block btn-secondary"
												disabled
											>
												Загрузка/Разгрузка
											</button>
											<div
												class="route-auction__item-distance"
											>
												Расстояние:
												{{
													numberRUFormat(
														Number(
															point.distance
														).toFixed(2)
													)
												}}
												км.
											</div>
										</div>
										<p
											v-if="
												point.department.mainWarehouse
													.address
											"
											class="route-auction__item-address"
										>
											{{
												point.department.mainWarehouse
													.address
											}}
										</p>
										<div
											v-if="point.rowNumber === 1"
											class="route-auction__item-footer"
										>
											<time
												class="route-auction__item-date"
												><i
													class="fa fa-calendar fa-sm mr-3"
												/>
												{{
													formatDate(
														form.playedFlights[
															index
														]
															.firstPointLoadingDatetime
													)
												}}
											</time>
											<time
												class="route-auction__item-time"
												><i
													class="fa fa-clock-o fa-sm mr-3"
												/>
												{{
													formatTime(
														form.playedFlights[
															index
														]
															.firstPointLoadingDatetime
													)
												}}
											</time>
										</div>
									</div>
								</div>
								<p
									class="route-and-flight-auction-details__route-container-total"
								>
									Итоговое расстояние:
									<b
										>{{
											numberRUFormat(
												data.playedFlights[index]
													.fullDistance
											)
										}}
										км</b
									>.
								</p>
								<p
									class="route-and-flight-auction-details__route-container-second"
								>
									Ориентировочное время в пути:
									<b>{{
										data.playedFlights[index].timeSummer
									}}</b>

									<small class="mt-4 mb-4"><strong> <br> *</strong> Точное время доставки будет указано в транспортной накладной после отправки.</small>
								</p>
							</div>
							<div
								class="route-and-flight-auction-details__dates"
							>
								<h6
									class="route-and-flight-auction-details__title custom-fieldset__title"
								>
									Детали рейса
								</h6>
								<p>
									Дата и время указаны в соответствии с
									часовым поясом первой точки маршрута
								</p>
								<div
									class="route-and-flight-auction-details__date"
								>
									<p
										class="route-and-flight-auction-details__date-title"
									>
										Плановая дата и время <b>прибытия</b> в
										первую точку маршрута:
									</p>
									<span
										class="route-and-flight-auction-details__date-number"
										><i
											class="fa fa-calendar fa-sm mr-3"
										/>{{
											formatDate(
												form.playedFlights[index]
													.firstPointArrivalDatetime
											)
										}}</span
									>
									<span
										class="route-and-flight-auction-details__date-time"
										><i
											class="fa fa-clock-o fa-sm mr-3"
										/>{{
											formatTime(
												form.playedFlights[index]
													.firstPointArrivalDatetime
											)
										}}</span
									>
								</div>
								<div
									class="route-and-flight-auction-details__date"
								>
									<p
										class="route-and-flight-auction-details__date-title"
									>
										Плановая дата и время начала
										<b>погрузки</b> в первой точке маршрута
									</p>
									<span
										class="route-and-flight-auction-details__date-number"
										><i
											class="fa fa-calendar fa-sm mr-3"
										/>{{
											formatDate(
												form.playedFlights[index]
													.firstPointLoadingDatetime
											)
										}}</span
									>
									<span
										class="route-and-flight-auction-details__date-time"
										><i
											class="fa fa-clock-o fa-sm mr-3"
										/>{{
											formatTime(
												form.playedFlights[index]
													.firstPointLoadingDatetime
											)
										}}</span
									>
								</div>
								<p
									class="route-and-flight-auction-details__type"
								>
									<b>Организация</b> —
									{{ form.playedFlights[index].organization }}
								</p>
								<p
									class="route-and-flight-auction-details__type"
								>
									<b>Тип перевозки</b> —
									{{ data.playedFlights[index].type }}
								</p>
								<p
									class="route-and-flight-auction-details__type"
								>
									<b>Вид перевозки</b> —
									{{ data.playedFlights[index].typeName }}
								</p>
								<b-form-group label="Ускоренный">
									<b-form-checkbox
										v-model="
											form.playedFlights[index]
												.accelerated
										"
										disabled
										name="accelerated"
									>
										Да
									</b-form-checkbox>
								</b-form-group>
								<b-form-group
									label="Обязательное наличие пропуска на МКАД"
								>
									<b-form-checkbox
										v-model="
											form.playedFlights[index].mkadPass
										"
										disabled
										name="mkadPass"
									>
										Да
									</b-form-checkbox>
								</b-form-group>
								<b-form-group
									label="Обязательная постановка ранее допущенного водителя"
								>
									<b-form-checkbox
										v-model="
											form.playedFlights[index]
												.retryDriver
										"
										disabled
										name="retryDriver"
									>
										Да
									</b-form-checkbox>
								</b-form-group>
								<p
									class="route-and-flight-auction-details__type"
								>
									<b>Стоимость рейса</b>
								</p>
								<b-form-group>
									<!-- <b-form-input
                    v-model="form.playedFlights[index].startPrice"
                    disabled
                    type="number"
                    autocomplete="false"
                  /> -->
									<h5 v-if="data.status !== 6">
										{{
											numberRUFormat(
												form.playedFlights[index]
													.startPrice
											)
										}}
										руб.
									</h5>
									<h5 v-else>
										{{
											numberRUFormat(
												form.playedFlights[index]
													.endPrice
											)
										}}
										руб.
									</h5>
								</b-form-group>
							</div>
              <div v-if="form.playedFlights[index].note.value && form.playedFlights[index].note.value !== undefined">
				<div class="mt-3 mb-4">
				<b-row>
					<b-col md="4">
						<p class="main-info__item-title">Комментарий к маршруту аукциона</p>
						<p class="main-info__item-desc">
							{{form.playedFlights[index].note.value}}
						</p>
					</b-col>
				</b-row>								
			</div>
        </div>
							<div
								class="route-and-flight-auction-details__transport"
							>
								<h6
									class="route-and-flight-auction-details__title custom-fieldset__title"
								>
									Транспорт
								</h6>
								<p>
									В рейсе необходимо будет использовать
									транспортные средства, соответствующие
									требованиям указанным ниже
								</p>
								<b-row>
									<b-col md="6">
										<b-form-group
											v-if="
												form.playedFlights[index]
													.loadingUnloading
											"
											class="route-and-flight-auction-details__form-group form-group-el-select"
											label="Погрузка/Выгрузка"
										>
											<b-form-checkbox-group
												v-model="
													form.playedFlights[index]
														.loadingUnloading.value
												"
												name="loading-unloading"
												stacked
												:disabled="true"
											>
												<b-form-checkbox
													v-for="(item,
													index) in loadingUnloadingOptions"
													:key="index"
													:value="item.value"
												>
													{{ item.text }}
												</b-form-checkbox>
											</b-form-checkbox-group>
										</b-form-group>
										<b-form-group
											class="route-and-flight-auction-details__form-group form-group-el-select"
										>
											<dimensions-view
												v-if="data.status !== 6"
												:value="
													dimensionsList(
														auctionRoutes[
															data.playedFlights[
																index
															].auctionRouteId
														].id,
														auctionRoutes[
															data.playedFlights[
																index
															].auctionRouteId
														].dimension,
														auctionRoutes[
															data.playedFlights[
																index
															].auctionRouteId
														].optionalDimensions
													)
												"
												:price="
													auctionRoutes[
														data.playedFlights[
															index
														].auctionRouteId
													].startPrice
												"
											/>
											<dimensions-view
												v-else
												:value="
													dimensionsList(
														auctionRoutes[
															data.playedFlights[
																index
															].auctionRouteId
														].id,
														auctionRoutes[
															data.playedFlights[
																index
															].auctionRouteId
														].dimension,
														auctionRoutes[
															data.playedFlights[
																index
															].auctionRouteId
														].optionalDimensions
													)
												"
												:price="
													auctionRoutes[
														data.playedFlights[
															index
														].auctionRouteId
													].endPrice
												"
											/>
										</b-form-group>
									</b-col>
									<b-col md="6">
										<b-form-group
											v-if="
												form.playedFlights[index]
													.bodyType
											"
											class="route-and-flight-auction-details__form-group form-group-el-select"
											label="Тип кузова"
										>
											<b-form-checkbox-group
												v-model="
													form.playedFlights[index]
														.bodyType.value
												"
												name="body-type"
												stacked
												:disabled="true"
											>
												<b-form-checkbox
													v-for="(item,
													index) in bodyTypeOptions"
													:key="index"
													:value="item.value"
												>
													{{ item.text }}
												</b-form-checkbox>
											</b-form-checkbox-group>
										</b-form-group>
									</b-col>
								</b-row>
							</div>
						</div>
					</div>
				</b-col>
				<b-col xl="8">
					<h5 class="custom-fieldset__title">
						<i class="fa fa-gavel fa-lg mr-2" />Аукцион
					</h5>
					<div
						class="contractors-auction-info"
						:class="{ draft: data.status === 1 }"
					>
						<div class="contractors-auction-info__top">
							<b-row no-gutters>
								<b-col v-if="data.status === 2" lg="6">
									<div class="contractors-auction-info__bet">
										<div
											class="contractors-auction-info__bet-timer"
										>
											<span
												class="contractors-auction-info__bet-timer-title"
												>Прием ставок</span
											>
											<span
												v-if="data.countdown"
												class="contractors-auction-info__bet-timer-value text-danger"
												><i
													class="fa fa-clock-o fa-sm mr-2"
												/>{{ data.countdown }}</span
											>
											<span
												v-else
												class="contractors-auction-info__bet-timer-value text-secondary"
												><i
													class="fa fa-clock-o fa-sm mr-2"
												/>{{
													formatDatetime(data.endDate)
												}}</span
											>
										</div>
										<div
											class="contractors-auction-info__bet-current-bet"
										>
											<span
												class="contractors-auction-info__bet-current-bet-title"
												>Лидирующая ставка:</span
											>
											<span
												v-if="data.smallestBid.bet"
												class="contractors-auction-info__bet-current-bet-value"
											>
												<span
													class="contractors-auction-info__bet-current-bet-value-number"
												>
													{{
														numberRUFormat(
															data.smallestBid.bet
														)
													}}
													<span v-if="form.VAT.value">
														С НДС
													</span>
													<span v-else>
														Без НДС
													</span>
												</span>
												<span
													class="contractors-auction-info__bet-current-bet-value-with-vat"
												>
													{{
														alternativeAmountValue(
															data.smallestBid.bet
														)
													}}
												</span>
											</span>
											<span
												v-else
												class="contractors-auction-info__bet-current-bet-value"
											>
												<span
													class="contractors-auction-info__bet-current-bet-value-number"
													>отсуствует</span
												>
											</span>
										</div>
										<div
											class="contractors-auction-info__bet-current-bet"
										>
											<span
												class="contractors-auction-info__bet-current-bet-title"
												>Ваша предыдущая ставка:
												<span
													v-if="
														data
															.smallestContractorBid
															.active
													"
													class="text-success"
													>(лидирует)</span
												><span
													v-else-if="
														data
															.smallestContractorBid
															.active === false
													"
													class="text-danger"
													>(проигрывает)</span
												></span
											>
											<span
												v-if="
													data.smallestContractorBid
														.bet
												"
												class="contractors-auction-info__bet-current-bet-value"
											>
												<span
													class="contractors-auction-info__bet-current-bet-value-number"
												>
													{{
														numberRUFormat(
															data
																.smallestContractorBid
																.bet
														)
													}}
													<span v-if="form.VAT.value">
														С НДС
													</span>
													<span v-else>
														Без НДС
													</span>
												</span>
												<span
													class="contractors-auction-info__bet-current-bet-value-with-vat"
												>
													{{
														alternativeAmountValue(
															data
																.smallestContractorBid
																.bet
														)
													}}
												</span>
											</span>
											<span
												v-else
												class="contractors-auction-info__bet-current-bet-value"
											>
												<span
													class="contractors-auction-info__bet-current-bet-value-number"
													>отсуствует</span
												>
											</span>
										</div>
										<div
											v-if="
												!data.smallestContractorBid.bet
											"
											class="contractors-auction-info__bet-my-bet"
										>
											<p
												class="contractors-auction-info__bet-my-bet-title"
											>
												Новая ставка
											</p>
											<div
												class="contractors-auction-info__bet-my-bet-field"
											>
												<button
													class="contractors-auction-info__bet-my-bet-field-minus"
													type="button"
													@click="nextBet()"
												>
													-
												</button>
												<div
													class="contractors-auction-info__bet-my-bet-field-value"
												>
													{{
														Number(
															data.newBet
														).toFixed(2)
													}}<i
														class="fa fa-ruble fa-sm ml-2"
													/>
													<span
														v-if="data.newBet"
														class="contractors-auction-info__bet-my-bet-field-desc text-secondary"
													>
														{{
															alternativeAmountValue(
																data.newBet
															)
														}}
														<span
															v-b-popover.hover="
																'Итоговая стоимость рейса определяется исходя из того, являетесь ли Вы плательщиком НДС'
															"
															class="el-icon-info"
														/>
													</span>
												</div>
												<button
													class="contractors-auction-info__bet-my-bet-field-plus"
													type="button"
													@click="prevBet()"
												>
													+
												</button>
											</div>
											<b-row
												v-for="(playedFlight,
												index) in data.playedFlights"
												:key="'played-route-' + index"
											>
												<b-col sm="6">
													<h6
														class="contractors-auction-info__data-subtitle text-right"
													>
														{{
															data.playedFlights[
																index
															].routeCode
														}}
													</h6>
												</b-col>
												<b-col sm="6">
													<span
														class="contractors-auction-info__data-value text-success"
													>
														<i
															class="fa fa-ruble fa-sm mr-2"
														/>{{
															numberRUFormat(
																form
																	.playedFlights[
																	index
																].curPrice
															)
														}}
													</span>
												</b-col>
											</b-row>
											<b-button
												class="contractors-auction-info__bet-my-bet-button"
												variant="success"
												size="lg"
												:class="{ loading: loading }"
												:disabled="
													data.newBet >=
														data.smallestBid.bet ||
														data.countdownFinished ||
														loading
												"
												@click="onAuctionBid()"
											>
												Сделать ставку
											</b-button>
										</div>
										<div
											v-else
											class="contractors-auction-info__bet-my-bet"
										>
											<p
												class="contractors-auction-info__bet-my-bet-title"
											>
												Новая ставка
											</p>
											<div
												class="contractors-auction-info__bet-my-bet-field"
											>
												<button
													class="contractors-auction-info__bet-my-bet-field-minus"
													type="button"
													@click="nextBet()"
												>
													-
												</button>
												<div
													class="contractors-auction-info__bet-my-bet-field-value"
												>
													{{
														Number(
															data.newBet
														).toFixed(2)
													}}<i
														class="fa fa-ruble fa-sm ml-2"
													/>
													<span
														v-if="data.newBet"
														class="contractors-auction-info__bet-my-bet-field-desc text-secondary"
													>
														{{
															alternativeAmountValue(
																data.newBet
															)
														}}
														<span
															v-b-popover.hover="
																'Итоговая стоимость рейса определяется исходя из того, являетесь ли Вы плательщиком НДС'
															"
															class="el-icon-info"
														/>
													</span>
												</div>
												<button
													class="contractors-auction-info__bet-my-bet-field-plus"
													type="button"
													@click="prevBet()"
												>
													+
												</button>
											</div>
											<b-row
												v-for="(playedFlight,
												index) in data.playedFlights"
												:key="'played-route-' + index"
											>
												<b-col sm="6">
													<h6
														class="contractors-auction-info__data-subtitle text-right"
													>
														{{
															data.playedFlights[
																index
															].routeCode
														}}
													</h6>
												</b-col>
												<b-col sm="6">
													<span
														class="contractors-auction-info__data-value text-success"
													>
														<i
															class="fa fa-ruble fa-sm mr-2"
														/>{{
															numberRUFormat(
																form
																	.playedFlights[
																	index
																].curPrice
															)
														}}
													</span>
												</b-col>
											</b-row>
											<b-button
												class="contractors-auction-info__bet-my-bet-button"
												variant="success"
												size="lg"
												:class="{ loading: loading }"
												:disabled="
													data.newBet >=
														data.smallestBid.bet ||
														data.countdownFinished ||
														loading
												"
												@click="onAuctionBid()"
											>
												Сделать ставку
											</b-button>
										</div>
									</div>
								</b-col>
								<b-col
									v-else-if="
										data.status === 4 || data.status === 3
									"
									lg="6"
								>
									<div class="contractors-auction-info__bet">
										<div
											class="contractors-auction-info__bet-my-bet"
										>
											<p
												v-if="
													data.smallestContractorBid
														.winner ||
														data.contractorOffer
															.winner
												"
												class="contractors-auction-info__bet-my-bet-title"
											>
												Поздравляем!
											</p>
											<p
												v-else
												class="contractors-auction-info__bet-my-bet-title"
											>
												Ожидайте.
											</p>
											<p
												v-if="
													data.smallestContractorBid
														.winner
												"
												class="contractors-auction-info__bet-my-bet-desc"
											>
												Вы назначены победителем
												аукциона, на основе финальной
												ставки:
											</p>
											<p
												v-else-if="
													data.contractorOffer.winner
												"
												class="contractors-auction-info__bet-my-bet-desc"
											>
												Вы назначены победителем
												аукциона, на основе встречного
												предложения:
											</p>
											<p
												v-else
												class="contractors-auction-info__bet-my-bet-desc"
											>
												Проходит подведение итогов.
											</p>
											<div
												class="contractors-auction-info__bet-my-bet-field"
											>
												<div
													v-if="
														data
															.smallestContractorBid
															.winner
													"
													class="contractors-auction-info__bet-my-bet-field-value"
												>
													{{
														numberRUFormat(
															Number(
																data
																	.smallestContractorBid
																	.bet
															).toFixed(2)
														)
													}}
													<i
														class="fa fa-ruble fa-sm ml-2"
													/>
													<span
														v-if="
															data
																.smallestContractorBid
																.bet
														"
														class="contractors-auction-info__bet-my-bet-field-desc text-secondary"
													>
														{{
															numberRUFormat(
																alternativeAmountValue(
																	data
																		.smallestContractorBid
																		.bet
																)
															)
														}}
														<span
															v-b-popover.hover="
																'Итоговая стоимость рейса определяется исходя из того, являетесь ли Вы плательщиком НДС'
															"
															class="el-icon-info"
														/>
													</span>
												</div>
												<div
													v-else-if="
														data.contractorOffer
															.winner
													"
													class="contractors-auction-info__bet-my-bet-field-value"
												>
													{{
														numberRUFormat(
															Number(
																data
																	.contractorOffer
																	.bet
															).toFixed(2)
														)
													}}<i
														class="fa fa-ruble fa-sm ml-2"
													/>
													<span
														v-if="
															data.contractorOffer
																.bet
														"
														class="contractors-auction-info__bet-my-bet-field-desc text-secondary"
													>
														{{
															numberRUFormat(
																alternativeAmountValue(
																	data
																		.contractorOffer
																		.bet
																)
															)
														}}
														<span
															v-b-popover.hover="
																'Итоговая стоимость рейса определяется исходя из того, являетесь ли Вы плательщиком НДС'
															"
															class="el-icon-info"
														/>
													</span>
												</div>
											</div>
											<b-row
												v-for="(playedFlight,
												index) in data.playedFlights"
												:key="'played-route-' + index"
											>
												<b-col sm="6">
													<h6
														class="contractors-auction-info__data-subtitle text-right"
													>
														{{
															data.playedFlights[
																index
															].routeCode
														}}
													</h6>
												</b-col>
												<b-col sm="6">
													<span
														class="contractors-auction-info__data-value text-success"
													>
														<i
															class="fa fa-ruble fa-sm mr-2"
														/>{{
															numberRUFormat(
																form
																	.playedFlights[
																	index
																].curPrice
															)
														}}
													</span>
												</b-col>
											</b-row>
											<b-button
												v-if="
													data.smallestContractorBid
														.winner
												"
												class="contractors-auction-info__bet-my-bet-button mt-2"
												variant="danger"
												size="sm"
												:class="{ loading: loading }"
												:disabled="loading"
												@click="onCancel('bid')"
											>
												Отказаться от рейса
											</b-button>
											<b-button
												v-else-if="
													data.contractorOffer.winner
												"
												class="contractors-auction-info__bet-my-bet-button mt-2"
												variant="danger"
												size="sm"
												:class="{ loading: loading }"
												:disabled="loading"
												@click="onCancel('offer')"
											>
												Отказаться от рейса
											</b-button>
										</div>
									</div>
								</b-col>
								<b-col v-else-if="data.status === 6" lg="6">
									<div class="contractors-auction-info__bet">
										<div
											class="contractors-auction-info__bet-my-bet"
										>
											<p
												class="contractors-auction-info__bet-my-bet-title"
											>
												Поздравляем!
											</p>
											<p
												v-if="
													data.smallestContractorBid
														.winner
												"
												class="contractors-auction-info__bet-my-bet-desc"
											>
												<span
													v-for="(playedFlight,
													index) in data.playedFlights"
													:key="
														'played-route-' + index
													"
													>{{
														playedFlight.routeCode
													}}</span
												>
												взяты в обработку по итогам
												победы в аукционе, на основе
												финальной ставки:
											</p>
											<p
												v-else-if="
													data.contractorOffer.winner
												"
												class="contractors-auction-info__bet-my-bet-desc"
											>
												<span
													v-for="(playedFlight,
													index) in data.playedFlights"
													:key="
														'played-route-' + index
													"
													>{{
														playedFlight.routeCode
													}}</span
												>
												взят в обработку по итогам
												победы в аукционе, на основе
												встречного предложения:
											</p>
											<div
												class="contractors-auction-info__bet-my-bet-field"
											>
												<div
													v-if="
														data
															.smallestContractorBid
															.winner
													"
													class="contractors-auction-info__bet-my-bet-field-value"
												>
													{{
														numberRUFormat(
															Number(
																data
																	.smallestContractorBid
																	.bet
															).toFixed(2)
														)
													}}<i
														class="fa fa-ruble fa-sm ml-2"
													/>
													<span
														v-if="
															data
																.smallestContractorBid
																.bet
														"
														class="contractors-auction-info__bet-my-bet-field-desc text-secondary"
													>
														{{
															alternativeAmountValue(
																data
																	.smallestContractorBid
																	.bet
															)
														}}
														<span
															v-b-popover.hover="
																'Итоговая стоимость рейса определяется исходя из того, являетесь ли Вы плательщиком НДС'
															"
															class="el-icon-info"
														/>
													</span>
												</div>
												<div
													v-else-if="
														data.contractorOffer
															.winner
													"
													class="contractors-auction-info__bet-my-bet-field-value"
												>
													{{
														numberRUFormat(
															Number(
																data
																	.contractorOffer
																	.bet
															).toFixed(2)
														)
													}}<i
														class="fa fa-ruble fa-sm ml-2"
													/>
													<span
														v-if="
															data.contractorOffer
																.bet
														"
														class="contractors-auction-info__bet-my-bet-field-desc text-secondary"
													>
														{{
															numberRUFormat(
																alternativeAmountValue(
																	data
																		.contractorOffer
																		.bet
																)
															)
														}}
														<span
															v-b-popover.hover="
																'Итоговая стоимость рейса определяется исходя из того, являетесь ли Вы плательщиком НДС'
															"
															class="el-icon-info"
														/>
													</span>
												</div>
											</div>
											<b-row
												v-for="(playedFlight,
												index) in data.playedFlights"
												:key="'played-route-' + index"
											>
												<b-col sm="6">
													<h6
														class="contractors-auction-info__data-subtitle text-right"
													>
														{{
															data.playedFlights[
																index
															].routeCode
														}}
													</h6>
												</b-col>
												<b-col sm="6">
													<span
														class="contractors-auction-info__data-value text-success"
													>
														<i
															class="fa fa-ruble fa-sm mr-2"
														/>{{
															numberRUFormat(
																form
																	.playedFlights[
																	index
																].curPrice
															)
														}}
													</span>
												</b-col>
											</b-row>
											<p
												class="contractors-auction-info__bet-my-bet-links"
											>
												Заполнить данные и отправить
												заявку на готовность вы можете
												на странице рейса по ссылкам:<br />
												<span
													v-for="(playedFlight,
													index) in data.playedFlights"
													:key="
														'played-route-' + index
													"
												>
													<a
														:href="
															`/contractors/id${$store.state.user.ownerId}/flight/id${index}`
														"
														>{{
															data.playedFlights[
																index
															].routeCode
														}}</a
													>
												</span>
											</p>
										</div>
									</div>
								</b-col>
								<b-col lg="6">
									<div class="contractors-auction-info__data">
										<div
											class="contractors-auction-info__data-item"
										>
											<h6
												class="contractors-auction-info__data-title"
											>
												Условия торгов:
											</h6>
											<b-row>
												<b-col sm="6">
													<h6
														class="contractors-auction-info__data-subtitle"
													>
														Общая начальная цена
													</h6>
												</b-col>
												<b-col sm="6">
													<span
														class="contractors-auction-info__data-value text-success"
													>
														<i
															class="fa fa-ruble fa-sm mr-2"
														/>{{
															numberRUFormat(
																form.startPrice
															)
														}}
														<span
															v-if="
																form.VAT.value
															"
															>С НДС</span
														><span v-else
															>Без НДС</span
														>
														<span
															v-b-popover.hover="
																'Итоговая стоимость рейса определяется исходя из того, являетесь ли Вы плательщиком НДС'
															"
															class="el-icon-info"
														/>
													</span>
													<span
														class="contractors-auction-info__data-value-desc text-secondary"
														>{{
															alternativeAmountValue(
																form.startPrice
															)
														}}</span
													>
												</b-col>
											</b-row>
											<b-row
												v-for="(playedFlight,
												index) in data.playedFlights"
												:key="'played-route-' + index"
											>
												<b-col sm="6">
													<h6
														class="contractors-auction-info__data-subtitle"
													>
														{{
															data.playedFlights[
																index
															].routeCode
														}}
													</h6>
												</b-col>
												<b-col sm="6">
													<span
														class="contractors-auction-info__data-value text-success"
													>
														<i
															class="fa fa-ruble fa-sm mr-2"
														/>{{
															numberRUFormat(
																form
																	.playedFlights[
																	index
																].startPrice
															)
														}}
														<span
															v-if="
																form.VAT.value
															"
															>С НДС</span
														><span v-else
															>Без НДС</span
														>
														<span
															v-b-popover.hover="
																'Итоговая стоимость рейса определяется исходя из того, являетесь ли Вы плательщиком НДС'
															"
															class="el-icon-info"
														/>
													</span>
													<span
														class="contractors-auction-info__data-value-desc text-secondary"
														>{{
															alternativeAmountValue(
																form
																	.playedFlights[
																	index
																].price
															)
														}}</span
													>
												</b-col>
											</b-row>
											<b-row>
												<b-col sm="6">
													<h6
														class="contractors-auction-info__data-subtitle"
													>
														Шаг ставки
													</h6>
												</b-col>
												<b-col sm="6">
													<span
														class="contractors-auction-info__data-value text-success"
														><i
															class="fa fa-ruble fa-sm mr-2"
														/>{{
															numberRUFormat(
																form.stepRate
															)
														}}
														{{
															alternativeAmountValue(
																form.stepRate
															)
														}}</span
													>
												</b-col>
											</b-row>
										</div>
										<div
											class="contractors-auction-info__data-item"
										>
											<h6
												class="contractors-auction-info__data-title"
											>
												Период проведения:
											</h6>
											<b-row>
												<b-col sm="6">
													<h6
														class="contractors-auction-info__data-subtitle"
													>
														Начало
													</h6>
												</b-col>
												<b-col sm="6">
													<span
														class="contractors-auction-info__data-date text-primary"
														><i
															class="fa fa-clock-o fa-sm mr-3"
														/>{{
															formatDatetime(
																data.startDate
															)
														}}</span
													>
												</b-col>
												<b-col sm="6">
													<h6
														class="contractors-auction-info__data-subtitle"
													>
														Завершение
													</h6>
												</b-col>
												<b-col sm="6">
													<span
														class="contractors-auction-info__data-date text-primary"
														><i
															class="fa fa-clock-o fa-sm mr-3"
														/>{{
															formatDatetime(
																data.endDate
															)
														}}</span
													>
												</b-col>
											</b-row>
										</div>
										<div
											class="contractors-auction-info__data-item"
										>
											<p
												class="contractors-auction-info__data-desc"
											>
												При розыгрыше двух и более
												рейсов в одном аукционе,
												стоимость каждого рейса
												снижается последовательно на
												сумму шага ставки.
											</p>
											<p
												class="contractors-auction-info__data-desc"
											>
												При подаче встречного
												предложения, общая стоимость
												распределяется в процентном
												соотношении от первоначальной
												стоимости каждого рейса.
											</p>
										</div>
									</div>
								</b-col>
							</b-row>
						</div>
						<div
							v-if="data.status === 2 && data.enableOffers"
							class="contractors-auction-info__bottom"
						>
							<div v-if="data.offerDate">
								<span
									v-if="data.offerCountdown"
									class="contractors-auction-info__bet-timer-value text-danger"
								>
									Прием встречных предложений станет возможен
									через:
									<i
										class="fa fa-clock-o fa-sm mr-1 ml-2"
									/>{{ data.offerCountdown }}
								</span>
								<span
									v-else
									class="contractors-auction-info__bet-timer-value text-secondary"
								>
									Прием встречных предложений станет возможен:
									<i
										class="fa fa-clock-o fa-sm mr-1 ml-2"
									/>{{ formatDatetime(data.offerDate) }}
								</span>
							</div>
							<div v-else>
								<div
									v-if="!data.contractorOffer.bet"
									class="contractors-auction-info__offer"
								>
									<p
										class="contractors-auction-info__offer-title"
									>
										Вы можете сделать встречное предложение
									</p>
									<b-row no-gutters>
										<b-col lg="6">
											<b-form-group
												class="contractors-auction-info__offer-input"
												:description="
													alternativeAmountValue(
														data.newOffer
													)
												"
											>
												<b-form-input
													v-model="data.newOffer"
													type="number"
													autocomplete="false"
													@wheel="
														$event.target.blur()
													"
												/>
											</b-form-group>
										</b-col>
										<b-col lg="6">
											<div
												class="contractors-auction-info__offer-button-container"
											>
												<b-button
													class="contractors-auction-info__offer-button"
													variant="warning"
													:class="{
														loading: loading,
													}"
													:disabled="loading"
													@click="onAuctionOffer()"
												>
													Сделать встречное
													предложение
												</b-button>
											</div>
										</b-col>
									</b-row>
								</div>
								<div
									v-else
									class="contractors-auction-info__offer"
								>
									<p
										class="contractors-auction-info__offer-title"
									>
										Ваше текущее предложение
									</p>
									<b-row no-gutters>
										<b-col lg="6">
											<b-form-group
												class="contractors-auction-info__offer-input"
												:description="
													alternativeAmountValue(
														data.newOffer
													)
												"
											>
												<b-form-input
													v-model="data.newOffer"
													type="number"
													autocomplete="false"
													@wheel="
														$event.target.blur()
													"
												/>
											</b-form-group>
											<div class="mt-3">
												<h6
													v-for="(summ, index) in data.contractorOffer.summs"
                          :key="'sum' + index"
													class="contractors-auction-info__data-subtitle"
												>
													{{
														auctionRoutes[summ.id]
															.route.routeCode
													}}
													<span
														class="contractors-auction-info__data-value text-success d-inline-block"
													>
														<i
															class="fa fa-ruble fa-sm mr-2 ml-3"
														/>{{
															numberRUFormat(
																summ.price
															)
														}}
														руб.
													</span>
												</h6>
											</div>
										</b-col>
										<b-col lg="6">
											<div
												class="contractors-auction-info__offer-button-container"
											>
												<b-button
													class="contractors-auction-info__offer-button"
													variant="warning"
													:class="{
														loading: loading,
													}"
													:disabled="loading"
													@click="
														onAuctionEditOffer()
													"
												>
													Сделать новое предложение
												</b-button>
											</div>
										</b-col>
									</b-row>
								</div>
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
			<div class="fixed-buttons">
				<b-button
					type="button"
					variant="secondary"
					class="mr-2"
					:class="{ loading: loading }"
					@click="$router.go(-1)"
				>
					Назад
				</b-button>
			</div>
		</div>
	</div>
</template>

<script>
import DimensionsView from "../../components/DimensionsView.vue";
import Vue from "vue";
import { ClientTable, Event } from "vue-tables-2";
import notifications from "../../components/mixins/notifications";
import {
	contractorsRoutesList,
	transportVehiclesAuctionBodyTypes,
	transportVehiclesLoadingTypes,
	transportVehiclesDimensionsTypes,
	contractorsAuctionRead,
	routeWayRead,
	contractorsAuctionBid,
	contractorsAuctionOffer,
	contractorsAuctionAcceptWin,
	contractorsAuctionDeclineWin,
	contractorsAuctionEditOffer,
	enterToAuctionPage,
	auctionBet,
} from "../../services/api";
import {
	valueToValueWithVAT,
	valueToValueWithoutVAT,
	numberToRUFormat,
} from "../../components/mixins/helpers";
import moment from "moment";
import tz from "moment-timezone";

Vue.use(ClientTable);

const socket = new WebSocket(process.env.VUE_APP_WEBSOCKET_AUCTION_API_URL);
const urlArray = window.location.pathname.split("/");
const auctionsId = urlArray[urlArray.length - 1].substr(2);

socket.onopen = () => {
	socket.send(
		JSON.stringify({
			event: "authorize",
			data: {
				jwt: localStorage.token,
				auctionId: Number(auctionsId),
			},
		})
	);
};

export default {
	name: "ContractorAuctionsView",
	components: {
		ClientTable,
		Event,
		DimensionsView,
	},
	mixins: [notifications],
	props: ["contractorId", "auctionsId"],
	data() {
		return {
		note: {
			id: null,
			value: null,
			},
			data: {
				status: null,
				countdown: null,
				countdownInterval: null,
				countdownFinished: false,
				enableOffers: null,
				offerDate: null,
				offerCountdown: null,
				offerCountdownInterval: null,
				routeWayName: "",
				dimension: "",
				startDate: "",
				endDate: "",
				playedFlights: {},
				smallestBid: {},
				newBet: null,
				smallestContractorBid: {},
				newOffer: null,
				contractorOffer: {},
				firstBidWin: null,
			},
			auctionRoutes: {},
			dimensionOptions: [],
			loadingUnloadingOptions: [],
			bodyTypeOptions: [],
			form: {
				playedFlightsSelect: {
					options: [],
					value: [],
				},
				playedFlights: {},
				size: {
					options: [],
					value: null,
				},
				listOfContractors: {
					options: [],
					value: null,
				},
				loadingUnloading: {
					options: [],
					value: [],
				},
				bodyType: {
					options: [],
					value: [],
				},
				VAT: {
					options: [
						{
							value: 0,
							text: "Без НДС",
						},
						{
							value: 1,
							text: "С НДС",
						},
					],
					value: null,
				},
				startPrice: null,
				endPrice: null,
				stepRate: null,
			},
			contractors: {
				columns: ["id", "name", "email", "trucksCount"],
				options: {
					headings: {
						id: "ID",
						name: "Название",
						email: "Email",
						trucksCount: "Кол-во автомобилей",
					},
					sortable: ["id", "name", "email", "trucksCount"],
					filterable: [],
					sortIcon: {
						base: "fa",
						up: "fa-sort-asc",
						down: "fa-sort-desc",
						is: "fa-sort",
					},
					pagination: {
						chunk: 10,
						edge: true,
						nav: "fixed",
					},
					perPage: 5,
					perPageValues: [5, 10, 25],
					texts: {
						count:
							"Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись",
						first: "Первая",
						last: "Последняя",
						filter: "Фильтр:",
						filterPlaceholder: "Поисковый запрос",
						limit: "Записи:",
						page: "Страница:",
						noResults: "Нет совпадающих записей",
						filterBy: "Фильтр по {column}",
						loading: "Загрузка...",
						defaultOption: "Выбор {column}",
						columns: "Столбцы",
					},
				},
				items: [],
				theme: "bootstrap4",
			},
			pickerOptions: {
				firstDayOfWeek: 1,
			},
			userPrice: 0,
			loading: false,
			useVuex: false,
			template: "default",
		};
	},
	sockets: {
		connect: function() {
			console.log("socket connected");
		},
		customEmit: function(data) {
			console.log(
				'this method was fired by the socket server. eg: io.emit("customEmit", data)'
			);
		},
	},
	computed: {
		dimensionsList(routeId, dimension, optionalDimensions) {
			return (routeId, dimension, optionalDimensions) => {
				return {
					id: routeId,
					...(dimension && { dimension: dimension }),
					...(optionalDimensions?.length > 0 && {
						optionalDimensions: optionalDimensions,
					}),
				};
			};
		},
	},
	watch: {
		$route() {
			this.contractorsAuctionRead();
		},
	},
	created() {
		socket.onmessage = (message) => {
			this.loading = true;
			try {
				const data = JSON.parse(message.data);

				if (data.status) {
					this.data.status = data.status;
				}
				
				if (data.enableOffers !== undefined) {
					this.data.enableOffers = data.enableOffers;
				}

				if (data.smallestContractorBid.active !== undefined) {
					this.data.smallestContractorBid.active = data.smallestContractorBid.active;
				}
				
				if (data.offerDate) {
					const eventTime = moment(data.offerDate).format("x");
					const currentTime = moment().format("x");
					const disableTime = 10000;
					const diffTime = eventTime - currentTime - disableTime;
					let duration = moment.duration(diffTime);
					const interval = 1000;

					if (
						diffTime > 0 &&
						this.data.offerDate !== data.offerDate
					) {
						this.data.offerDate = data.offerDate;
						clearInterval(this.data.offerCountdownInterval);
						this.data.offerCountdownInterval = setInterval(() => {
							duration = moment.duration(
								duration - interval,
								"milliseconds"
							);
							if (duration.days() === 0) {
								if (
									duration.hours() > 0 ||
									duration.minutes() > 0 ||
									duration.seconds() > 0
								) {
									this.data.offerCountdown =
										duration.hours() +
										"ч : " +
										duration.minutes() +
										"м " +
										duration.seconds() +
										"сек";
								} else {
									this.data.offerDate = null;
								}
							}
						}, interval);
					}
				}
				// playedFlightsSelect start
				this.form.playedFlightsSelect.value = data.auctionRoutes.map(
					(auctionRoute) => auctionRoute.route.id
				);
				this.form.playedFlightsSelect.options = data.auctionRoutes.map(
					(auctionRoute) => {
						return {
							value: auctionRoute.route.id,
							text: auctionRoute.route.routeCode,
						};
					}
				);
				// playedFlightsSelect end
				// auction start
				if (data.startDate) {
					this.data.startDate = data.startDate;
				}
				if (data.factEndDate) {
					this.data.endDate = data.factEndDate;
				} else if (data.stopDate) {
					if (this.data.endDate !== data.stopDate) {
						this.data.endDate = data.stopDate;

						const eventTime = moment(data.stopDate).format("x");
						const currentTime = moment().format("x");
						const disableTime = 10000;
						const diffTime = eventTime - currentTime - disableTime;
						let duration = moment.duration(diffTime);
						const interval = 1000;

						clearInterval(this.data.countdownInterval);
						this.data.countdownInterval = setInterval(() => {
							duration = moment.duration(
								duration - interval,
								"milliseconds"
							);
							if (duration.days() === 0) {
								this.data.countdown =
									duration.hours() +
									"ч : " +
									duration.minutes() +
									"м " +
									duration.seconds() +
									"сек";
							}
							if (duration._milliseconds <= 999) {
								clearInterval(this.data.countdownInterval);
								this.data.countdownFinished = true;
							}
						}, interval);
					}
				}
				if (data.smallestBid) {
					this.data.smallestBid = data.smallestBid;
					this.data.newBet = data.smallestBid.bet;
				} else {
					this.data.newBet = data.startPrice;
				}
				if (data.smallestContractorBid) {
					this.data.smallestContractorBid =
						data.smallestContractorBid;
				}
				if (data.contractorOffer) {
					this.data.contractorOffer = data.contractorOffer;
					this.data.newOffer = data.contractorOffer.bet;
				}
				if (data.startPrice) {
					this.form.startPrice = data.startPrice;
				}
				if (data.endPrice) {
					this.form.endPrice = data.endPrice;
				}
				if (data.step) {
					this.form.stepRate = data.step;
				}
				if (data.withNDS && data.withNDS !== undefined) {
					this.form.VAT.value = 1;
				} else {
					this.form.VAT.value = 0;
				}
				if (data.auctionHistoryMessages) {
					this.data.history = data.auctionHistoryMessages.sort(
						(a, b) => b.id - a.id
					);
				}
				if (data.possibleParticipants.length > 0) {
					this.contractors.items = data.possibleParticipants;
				}
				if (data.status === 6 && !data.winner) {
					this.showInfo("Аукцион окончен. Победитель утвержден.");
					this.$router.push({ path: "/auctions/contractor" });
				}
				// auction end
				// auctionRoute start
				data.auctionRoutes.forEach((auctionRoute) => {
					this.auctionRoutes[auctionRoute.id] = auctionRoute;
					Vue.set(this.data.playedFlights, auctionRoute.route.id, {});
					Vue.set(this.form.playedFlights, auctionRoute.route.id, {});
					Vue.set(
						this.data.playedFlights[auctionRoute.route.id],
						"auctionRouteId",
						auctionRoute.id
					);
					Vue.set(
						this.data.playedFlights[auctionRoute.route.id],
						"routeCode",
						auctionRoute.route.routeCode
					);
          Vue.set(
            this.data.playedFlights[auctionRoute.route.id],
            "routeName",
            auctionRoute.route.routeWay.code
          );
					Vue.set(
						this.data.playedFlights[auctionRoute.route.id],
						"type",
						auctionRoute.route.transportation.name
					);
					Vue.set(
						this.data.playedFlights[auctionRoute.route.id],
						"typeName",
						auctionRoute.route.transportationType.name
					);
					const clientTimezone = Intl.DateTimeFormat().resolvedOptions()
						.timeZone;
					if (auctionRoute.route.planDateOfFirstPointArrive) {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"firstPointArrivalDatetime",
							moment(
								auctionRoute.route.planDateOfFirstPointArrive,
								"YYYY-MM-DDTHH:mm:ss"
							)
								.tz(clientTimezone)
								.format()
						);
					}
					if (auctionRoute.route.planDateOfFirstPointLoading) {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"firstPointLoadingDatetime",
							moment(
								auctionRoute.route.planDateOfFirstPointLoading,
								"YYYY-MM-DDTHH:mm:ss"
							)
								.tz(clientTimezone)
								.format()
						);
					}
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"accelerated",
						auctionRoute.route.boostFlag
					);
					this.onRouteChange(
						auctionRoute.route.routeWay.id,
						auctionRoute.route.id
					);
					if (auctionRoute.dimension) {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"dimension",
							{ value: auctionRoute.dimension.id }
						);
					}
					if (auctionRoute.loadingTypes.length > 0) {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"loadingUnloading",
							{
								value: auctionRoute.loadingTypes.map(
									(item) => item.id
								),
							}
						);
					}
					if (auctionRoute.bodyTypes.length > 0) {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"bodyType",
							{
								value: auctionRoute.bodyTypes.map(
									(item) => item.id
								),
							}
						);
					}

					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"note",
						{id: null,
						value: null}
					)
					
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"mkadPass",
						auctionRoute.mkadPass
					);
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"retryDriver",
						auctionRoute.retryDriver
					);
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"startPrice",
						auctionRoute.startPrice
					);
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"endPrice",
						auctionRoute.endPrice
					);
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"curPrice",
						auctionRoute.curPrice
					);
				});

				// auctionRoute end
			} catch (e) {
				console.log(e);
			}
			this.loading = false;
		};
		socket.onclose = (event) => {
			console.log("onclose");
			console.log(event);
		};
		socket.onerror = (error) => {
			console.log("onerror");
			console.log("Ошибка " + error.message);
		};
	},
	mounted() {
		this.contractorsAuctionRead();
		this.transportVehiclesAuctionBodyTypes();
		this.transportVehiclesLoadingTypes();
		this.transportVehiclesDimensionsTypes();
	},
	methods: {
		numberRUFormat(value) {
			return numberToRUFormat(value);
		},
		formatDate(date) {
			return date ? moment(date).format("DD.MM.YYYY") : "";
		},
		formatTime(date) {
			return date ? moment(date).format("HH:mm") : "";
		},
		formatDatetime(date) {
			return date ? moment(date).format("DD.MM.YYYY HH:mm") : "";
		},
		alternativeAmountValue(value) {
			if (value) {
				if (!this.form.VAT.value) {
					return (
						"(" +
						this.numberRUFormat(valueToValueWithVAT(value)) +
						" руб. С НДС)"
					);
				} else {
					return (
						"(" +
						this.numberRUFormat(valueToValueWithoutVAT(value)) +
						" руб. Без НДС)"
					);
				}
			} else {
				return "";
			}
		},
		prevBet() {
			let maximalNewBet = null;
			if (this.data.smallestBid.bet) {
				maximalNewBet = this.data.smallestBid.bet;
			} else {
				maximalNewBet = this.form.startPrice;
			}
			if (Number(this.data.newBet) < Number(maximalNewBet)) {
				this.data.newBet =
					Number(this.data.newBet) + Number(this.form.stepRate);
			}
		},
		nextBet() {
			if (Number(this.data.newBet) > Number(this.form.stepRate)) {
				this.data.newBet =
					Number(this.data.newBet) - Number(this.form.stepRate);
			}
		},
		async contractorsAuctionRead() {
			this.loading = true;
			const response = await contractorsAuctionRead(
				this.contractorId,
				this.auctionsId
			);
			if (response && response.status === 200) {
				if (response.data.status) {
					this.data.status = response.data.status;
				}			
				if (response.data.firstBidWin !== undefined) {
					this.data.firstBidWin = response.data.firstBidWin;
				}
				if (response.data.enableOffers !== undefined) {
					this.data.enableOffers = response.data.enableOffers;
				}
								
				if (response.data.offerDate) {
					const eventTime = moment(response.data.offerDate).format(
						"x"
					);
					const currentTime = moment().format("x");
					const disableTime = 10000;
					const diffTime = eventTime - currentTime - disableTime;
					let duration = moment.duration(diffTime);
					const interval = 1000;

					if (
						diffTime > 0 &&
						this.data.offerDate !== response.data.offerDate
					) {
						this.data.offerDate = response.data.offerDate;
						clearInterval(this.data.offerCountdownInterval);
						this.data.offerCountdownInterval = setInterval(() => {
							duration = moment.duration(
								duration - interval,
								"milliseconds"
							);
							if (duration.days() === 0) {
								if (
									duration.hours() > 0 ||
									duration.minutes() > 0 ||
									duration.seconds() > 0
								) {
									this.data.offerCountdown =
										duration.hours() +
										"ч : " +
										duration.minutes() +
										"м " +
										duration.seconds() +
										"сек";
								} else {
									this.data.offerDate = null;
								}
							}
						}, interval);
					}
				}
				// playedFlightsSelect start
				this.form.playedFlightsSelect.value = response.data.auctionRoutes.map(
					(auctionRoute) => auctionRoute.route.id
				);
				this.form.playedFlightsSelect.options = response.data.auctionRoutes.map(
					(auctionRoute) => {
						return {
							value: auctionRoute.route.id,
							text: auctionRoute.route.routeCode,
						};
					}
				);
				// playedFlightsSelect end
				// auction start
				if (response.data.startDate) {
					this.data.startDate = response.data.startDate;
				}
				if (response.data.factEndDate) {
					this.data.endDate = response.data.factEndDate;
				} else if (response.data.stopDate) {
					if (this.data.endDate !== response.data.stopDate) {
						this.data.endDate = response.data.stopDate;

						const eventTime = moment(response.data.stopDate).format(
							"x"
						);
						const currentTime = moment().format("x");
						const disableTime = 10000;
						const diffTime = eventTime - currentTime - disableTime;
						let duration = moment.duration(diffTime);
						const interval = 1000;
						clearInterval(this.data.countdownInterval);
						this.data.countdownInterval = setInterval(() => {
							duration = moment.duration(
								duration - interval,
								"milliseconds"
							);
							if (duration.days() === 0) {
								this.data.countdown =
									duration.hours() +
									"ч : " +
									duration.minutes() +
									"м " +
									duration.seconds() +
									"сек";
							}
							if (duration._milliseconds <= 999) {
								clearInterval(this.data.countdownInterval);
								this.data.countdownFinished = true;
							}
						}, interval);
					}
				}
				if (response.data.smallestBid) {
					this.data.smallestBid = response.data.smallestBid;
					this.data.newBet = response.data.smallestBid.bet;
				} else {
					this.data.newBet = response.data.startPrice;
				}
				if (response.data.smallestContractorBid) {
					this.data.smallestContractorBid =
						response.data.smallestContractorBid;
				}
				if (response.data.contractorOffer) {
					this.data.contractorOffer = response.data.contractorOffer;
					this.data.newOffer = response.data.contractorOffer.bet;
				}
				if (response.data.startPrice) {
					this.form.startPrice = response.data.startPrice;
				}
				if (response.data.endPrice) {
					this.form.endPrice = response.data.endPrice;
				}
				if (response.data.step) {
					this.form.stepRate = response.data.step;
				}
				if (response.data.withNDS) {
					this.form.VAT.value = 1;
				} else {
					this.form.VAT.value = 0;
				}
				if (response.data.auctionHistoryMessages) {
					this.data.history = response.data.auctionHistoryMessages.sort(
						(a, b) => b.id - a.id
					);
				}
				if (response.data.possibleParticipants.length > 0) {
					this.contractors.items = response.data.possibleParticipants;
				}
				// auction end
				// auctionRoute start
				response.data.auctionRoutes.forEach((auctionRoute) => {
					this.auctionRoutes[auctionRoute.id] = auctionRoute;
					Vue.set(this.data.playedFlights, auctionRoute.route.id, {});
					Vue.set(this.form.playedFlights, auctionRoute.route.id, {});
					Vue.set(
						this.data.playedFlights[auctionRoute.route.id],
						"auctionRouteId",
						auctionRoute.id
					);
					Vue.set(
						this.data.playedFlights[auctionRoute.route.id],
						"routeCode",
						auctionRoute.route.routeCode
					);
          Vue.set(
            this.data.playedFlights[auctionRoute.route.id],
            "routeName",
            auctionRoute.route.routeWay.code
          );
					Vue.set(
						this.data.playedFlights[auctionRoute.route.id],
						"type",
						auctionRoute.route.transportation.name
					);
					Vue.set(
						this.data.playedFlights[auctionRoute.route.id],
						"typeName",
						auctionRoute.route.transportationType.name
					);
					const clientTimezone = Intl.DateTimeFormat().resolvedOptions()
						.timeZone;
					if (auctionRoute.route.planDateOfFirstPointArrive) {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"firstPointArrivalDatetime",
							moment(
								auctionRoute.route.planDateOfFirstPointArrive,
								"YYYY-MM-DDTHH:mm:ss"
							)
								.tz(clientTimezone)
								.format()
						);
					}
					if (auctionRoute.route.planDateOfFirstPointLoading) {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"firstPointLoadingDatetime",
							moment(
								auctionRoute.route.planDateOfFirstPointLoading,
								"YYYY-MM-DDTHH:mm:ss"
							)
								.tz(clientTimezone)
								.format()
						);
					}
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"accelerated",
						auctionRoute.route.boostFlag
					);
					this.onRouteChange(
						auctionRoute.route.routeWay.id,
						auctionRoute.route.id
					);
					if (auctionRoute.dimension) {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"dimension",
							{ value: auctionRoute.dimension.id }
						);
					}
					if (auctionRoute.loadingTypes.length > 0) {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"loadingUnloading",
							{
								value: auctionRoute.loadingTypes.map(
									(item) => item.id
								),
							}
						);
					}
					if (auctionRoute.bodyTypes.length > 0) {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"bodyType",
							{
								value: auctionRoute.bodyTypes.map(
									(item) => item.id
								),
							}
						);
					}
					if (auctionRoute.route.organization) {
						Vue.set(
							this.form.playedFlights[auctionRoute.route.id],
							"organization",
							auctionRoute.route.organization.name
						);
					}

					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"note",
						{id: null,
						value: null}
					);

					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"mkadPass",
						auctionRoute.mkadPass
					);
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"retryDriver",
						auctionRoute.retryDriver
					);
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"startPrice",
						auctionRoute.startPrice
					);
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"endPrice",
						auctionRoute.endPrice
					);
					Vue.set(
						this.form.playedFlights[auctionRoute.route.id],
						"curPrice",
						auctionRoute.curPrice
					);
				});
				if (response.data.routes) {
            response.data.routes.forEach((route) => {
				if (route.comment) {
					Vue.set(this.form.playedFlights[route.id], "note", {value: route.comment.comment, id: route.comment.id});
				}
            });       
        }
				this.collectData();
				// auctionRoute end
			}
			this.loading = false;
		},
		async contractorsRoutesList(name) {
			this.loading = true;
			const response = await contractorsRoutesList(this.contractorId, {
				name: name,
				limit: 100,
			});
			if (response && response.status === 200) {
				this.form.playedFlight.options = response.data.items.map(
					(item) => {
						return {
							value: item.id,
							text: item.routeCode,
						};
					}
				);
			}
			this.loading = false;
		},
		async transportVehiclesAuctionBodyTypes() {
			this.loading = true;
			const response = await transportVehiclesAuctionBodyTypes({
				limit: 100,
			});
			if (response && response.status === 200) {
				this.bodyTypeOptions = response.data.items.map((item) => {
					return { value: item.id, text: item.name };
				});
			}
			this.loading = false;
		},
		async transportVehiclesLoadingTypes() {
			this.loading = true;
			const response = await transportVehiclesLoadingTypes({
				limit: 100,
			});
			if (response && response.status === 200) {
				this.loadingUnloadingOptions = response.data.items.map(
					(item) => {
						return { value: item.id, text: item.name };
					}
				);
			}
			this.loading = false;
		},
		async transportVehiclesDimensionsTypes() {
			this.loading = true;
			const response = await transportVehiclesDimensionsTypes({
				limit: 100,
			});
			if (response && response.status === 200) {
				this.dimensionOptions = response.data.items.map((item) => {
					return { value: item.id, text: item.name };
				});
			}
			this.loading = false;
		},
		async onRouteChange(value, routeId) {
			this.loading = true;
			const response = await routeWayRead(value);
			if (response && response.status === 200) {
				Vue.set(
					this.data.playedFlights[routeId],
					"points",
					response.data.routeWayPoints.sort(
						(a, b) => a.rowNumber - b.rowNumber
					)
				);
				let lowestPoint = null;
				response.data.routeWayPoints.forEach((point) => {
					if (
						!lowestPoint ||
						point.rowNumber < lowestPoint.rowNumber
					) {
						lowestPoint = point;
					}
				});
				if (lowestPoint) {
					Vue.set(
						this.data.playedFlights[routeId],
						"firstRouteWayTimezone",
						lowestPoint.department.timeZone
					);
				}
				Vue.set(
					this.data.playedFlights[routeId],
					"fullDistance",
					response.data.routeWayPoints
						.reduce(function(accumulator, currentValue) {
							return accumulator + Number(currentValue.distance);
						}, 0)
						.toFixed(2)
				);

				Vue.set(
					this.data.playedFlights[routeId],
					"movementPoints",
					this.auctionRoutes[
						this.data.playedFlights[routeId].auctionRouteId
					].route.movementPoints.sort(
						(a, b) => a.rowNumber - b.rowNumber
					)
				);

				Vue.set(
					this.data.playedFlights[routeId],
					"guid",
					this.auctionRoutes[
						this.data.playedFlights[routeId].auctionRouteId
					].route.transportation.guid
				);

				if (
					this.data.playedFlights[routeId].guid ===
						"4b714ab4-4c44-4578-9a7a-06dbe77d8c45" ||
					this.data.playedFlights[routeId].guid ===
						"9ec087d0-917c-4fbb-b714-80ab7603fd6e"
				) {
					this.data.playedFlights[routeId].movementPoints.forEach(
						(point, index) => {
							if (
								this.data.playedFlights[routeId].movementPoints[
									index
								].pointAddress &&
								this.data.playedFlights[routeId].movementPoints[
									index
								].pointAddress !== ""
							) {
								this.data.playedFlights[routeId].points[
									index
								].department.mainWarehouse.address = this.data.playedFlights[
									routeId
								].movementPoints[index].pointAddress;
							}
						}
					);
				}

				Vue.set(
					this.data.playedFlights[routeId],
					"timeSummer",
					response.data.routeWayPoints
						.reduce(function(accumulator, currentValue) {
							return (
								accumulator + Number(currentValue.timeSummer)
							);
						}, 0)
						.toFixed(2)
				);
			}
			this.loading = false;
		},
		async onAuctionBid() {
			this.loading = true;
			this.userPrice = Number(this.data.newBet);
			const response = await contractorsAuctionBid(this.auctionsId, {
				bet: this.data.newBet,
			});
			if (response && response.status === 201) {
				this.showSuccess("Ставка сделана");
				this.contractorsAuctionRead();
			}
			this.loading = false;
		},
		async onAuctionOffer() {
			this.loading = true;
			this.userPrice = Number(this.data.newOffer);
			const response = await contractorsAuctionOffer(this.auctionsId, {
				bet: this.data.newOffer,
			});
			if (response && response.status === 201) {
				this.showSuccess("Предложение отправлено");
				this.contractorsAuctionRead();
			}
			this.loading = false;
		},
		async onAuctionEditOffer() {
			this.loading = true;
			this.userPrice = Number(this.data.newOffer);
			const response = await contractorsAuctionEditOffer(
				this.auctionsId,
				this.data.contractorOffer.id,
				{ bet: this.data.newOffer }
			);
			if (response && response.status === 201) {
				this.showSuccess("Предложение отправлено");
				this.contractorsAuctionRead();
			}
			this.loading = false;
		},
		async onCancel(type) {
			this.loading = true;
			const response = await contractorsAuctionDeclineWin(
				this.contractorId,
				routeWays,
				this.auctionsId,
				type
			);
			if (response && response.status === 200) {
				this.showSuccess("Вы отказались от победы в аукционе");
				this.contractorsAuctionRead();
			}
			this.loading = false;
		},
		async collectData() {
			let routeWays = [],
				routeCode = [],
				currentPrice = [],
				dateNow = new Date(),
				data = {
					date: dateNow.toLocaleDateString("ru-Ru", {timeZone: "Europe/Moscow"}),
					time: dateNow.toLocaleTimeString("ru-Ru", {timeZone: "Europe/Moscow"}),
					organization: this.$store.getters["user"].company.replace(
						/[^А-яЁё A-Za-z]/g,
						""
					),
					clickType:  "Зашел в закупку",
					pageType: "Аукцион",
					sessionId: this.$cookies.get('Session'),
					purchaseId: Number(this.auctionsId),
					routeWays: routeWays,
					routeCode: routeCode,
					startPrice: Number(this.form.startPrice),
					currentPrice: currentPrice,
					inn: this.$store.getters["user"].INN,
				};
			for (let item in this.data.playedFlights) {
				routeWays.push(this.data.playedFlights[item].routeCode);
			}

			for (let item in this.data.playedFlights) {
				routeCode.push(this.data.playedFlights[item].routeName);
			}

			if (data["clickType"] === "Зашел в закупку") {
				data["winner"] = null;
			}

			if (this.data.smallestBid.bet === null || this.data.smallestBid.bet === undefined) {
				data["currentPrice"].push(data["startPrice"]);
			} else {
				data["currentPrice"].push(Number(this.data.smallestBid.bet));
				data["totalPrice"] = Number(this.data.smallestBid.bet);
			}


			if (this.data.status === 6) {
					let currentPrice = [];
					data["clickType"] = "Итоги подведены";
					data["currentPrice"] = currentPrice;

				if (this.form.endPrice) {
					data["currentPrice"].push(Number(this.form.endPrice));
				}
				
				if (this.data.smallestContractorBid.winner == true || this.data.firstBidWin) {
					data["winner"] = "Да";
				} else {
					data["winner"] = "Нет";
				};
				await auctionBet(data);
				}

			if (this.userPrice) {
				data["newPrice"] = Number(this.userPrice);

				if (data["newPrice"]) {
					if (data["newPrice"] > data["startPrice"]) {
						if (this.data.smallestBid.bet === null || this.data.smallestBid.bet === undefined) {
							data["currentPrice"] = [];
							data["currentPrice"].push(data["startPrice"]);
						} else {
							data["currentPrice"] = [];
							data["currentPrice"].push(Number(this.data.smallestBid.bet));
						}	
					} else {
						data['currentPrice'] = [];
						let addPrice = [];
						this.data.history.forEach(item => {
						if (item.type === "newBid") {
							addPrice.push(item.data.bet);
						}
						});

						
						if (Number(addPrice[1])) {
							data['currentPrice'].push(Number(addPrice[1]));
						} else {
							data['currentPrice'].push(Number(data["startPrice"]));
						}						
					}
				}
				data["clickType"] = "Подача заявки в закупке";
				await auctionBet(data);
			} else if (this.data.status === 2) {
				await enterToAuctionPage(data);	
			}
		},
	},
};
</script>

<style lang="scss">
.app-body {
	overflow-x: initial;
}

.auction-info {
	overflow: hidden;
	background: #f3f4f5;
	border-radius: 5px;

	&__header {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-left: -15px;
		margin-right: -15px;
		padding: 5px 10px;
		border-bottom: 1px solid #c8ced3;

		&-item {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	&__body {
		padding: 5px 10px;

		&-title {
			margin-top: 24px;
			margin-bottom: 24px;
			font-size: 36px;
			font-weight: 700;
			text-align: center;
		}

		&-subtitle {
			margin-top: 24px;
			margin-bottom: 24px;
			font-size: 16px;
			font-weight: 700;
			text-align: center;
		}

		&-dates {
			display: flex;
			max-width: 480px;
			margin: 24px auto;
		}

		&-start-date {
			flex: 0 0 50%;
			max-width: 50%;
			font-size: 14px;
		}

		&-end-date {
			flex: 0 0 50%;
			max-width: 50%;
			font-size: 14px;
			text-align: right;
		}

		&-buttons {
			display: flex;
			justify-content: center;
			margin-top: 60px;
		}
	}

	&__previous-bet {
		position: relative;
		min-height: 100%;
		padding: 5px 10px;

		&:after {
			position: absolute;
			top: 0;
			right: 0;
			transform: translateX(50%);
			display: block;
			width: 1px;
			height: 100%;
			background-color: #c8ced3;
			content: "";
		}

		&-title {
			font-weight: 700;
			font-size: 14px;
		}

		&-list {
			max-height: 271px;
			overflow: auto;
			background-color: white;
			border: 1px solid #c8ced3;
		}

		&-item {
			padding: 2px 5px;
			border-bottom: 1px solid #c8ced3;

			&:last-child {
				border-bottom: 0;
			}

			&-header {
				display: flex;
				justify-content: space-between;
				margin-bottom: 10px;
			}

			&-date {
				font-weight: 700;
				font-size: 11px;
			}

			&-type {
				font-size: 11px;
			}

			&-info {
				position: relative;
				display: flex;
				justify-content: space-between;
				margin-top: 10px;
				margin-bottom: 10px;

				&:before {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 28px;
					height: 2px;
					background-color: #20a8d8;
					content: "";
				}
			}

			&-contractor {
				flex: 0 0 50%;
				max-width: 50%;
				padding-right: 15px;
				font-weight: 700;
				font-size: 13px;
			}

			&-price {
				flex: 0 0 50%;
				max-width: 50%;
				padding-left: 15px;
				font-weight: 700;
				font-size: 14px;
			}

			&-buttons {
				display: flex;
				justify-content: center;
				margin-top: 10px;
			}
		}
	}

	&__last-bet {
		position: relative;
		padding: 5px 10px;

		&-title {
			margin-bottom: 24px;
			font-weight: 700;
			font-size: 36px;
			text-align: center;
		}

		&-time {
			position: absolute;
			top: 5px;
			right: 10px;
			margin-top: 0;
			margin-bottom: 0;
			font-weight: 700;
			line-height: 27px;
			text-align: right;
		}

		&-current-bid {
			display: flex;
			align-items: flex-end;
			margin-top: 16px;
			margin-bottom: 16px;

			&-title {
				min-width: 200px;
				font-weight: 700;
				font-size: 14px;
			}

			&-value {
				font-weight: 700;
				font-size: 28px;
				line-height: normal;
				color: #4dbd74;

				&_no-winner {
					font-style: normal;
					font-weight: 700;
					font-size: 14px;
					color: #c8ced3;
				}

				&-vat {
					font-size: 12px;
					color: #636f83;
				}
			}
		}

		&-current-contractor {
			display: flex;
			align-items: flex-end;
			margin-top: 16px;
			margin-bottom: 16px;

			&-title {
				min-width: 200px;
				font-weight: 700;
				font-size: 14px;
			}

			&-value {
				font-style: italic;
				font-weight: 700;
				font-size: 18px;
				line-height: normal;
				color: #20a8d8;

				&_no-winner {
					font-style: normal;
					font-weight: 700;
					font-size: 14px;
					color: #c8ced3;
				}
			}
		}

		&-current-time {
			display: flex;
			align-items: flex-end;
			margin-top: 16px;
			margin-bottom: 16px;

			&-title {
				min-width: 200px;
				font-weight: 700;
				font-size: 14px;
			}

			&-value {
				font-weight: 700;
				font-size: 14px;
				color: #20a8d8;

				&_no-winner {
					font-style: normal;
					font-weight: 700;
					font-size: 14px;
					color: #c8ced3;
				}
			}
		}

		&-buttons {
			display: flex;
			justify-content: center;
			margin-top: 20px;
		}
	}
}

.contractors-auction-info {
	position: sticky;
	top: 65px;
	background: #f3f4f5;
	border: 1px solid #c8ced3;

	&.draft {
		&:after {
			display: none;
		}
	}

	&__top {
		position: relative;

		&:after {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			display: block;
			width: 1px;
			height: 100%;
			background-color: #c8ced3;
			content: "";
		}
	}

	&__bet {
		&-timer {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding: 5px 10px;
			border-bottom: 1px solid #c8ced3;

			&-title {
				font-size: 14px;
				font-weight: 700;
			}

			&-value {
				font-size: 14px;
				font-weight: 700;
			}
		}

		&-current-bet {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding: 5px 10px;
			border-bottom: 1px dashed #c8ced3;

			&-title {
				font-size: 14px;
				font-weight: 700;
			}

			&-value {
				&-number {
					display: block;
					font-size: 16px;
					font-weight: 700;
					text-align: right;
				}

				&-with-vat {
					display: block;
					font-size: 10px;
					font-style: italic;
					font-weight: 700;
					text-align: right;
				}
			}
		}

		&-my-bet {
			padding: 5px 10px;

			&-title {
				margin-top: 0;
				margin-bottom: 0;
				font-weight: 700;
				font-size: 28px;
				line-height: normal;
				text-align: center;
			}

			&-desc {
				max-width: 320px;
				margin: 0 auto;
				font-weight: 700;
				font-size: 16px;
				line-height: normal;
				text-align: center;

				span {
					&:after {
						content: "/";
					}

					&:last-child {
						&:after {
							display: none;
						}
					}
				}
			}

			&-status {
				margin-top: 0;
				margin-bottom: 0;
				font-weight: 700;
				font-size: 16px;
				line-height: normal;
				text-align: center;
			}

			&-field {
				position: relative;
				display: block;
				margin-top: 24px;
				margin-bottom: 30px;
				padding-left: 50px;
				padding-right: 50px;
				background-color: #ebedef;

				&-minus {
					position: absolute;
					top: 0;
					left: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 45px;
					height: 45px;
					padding: 0;
					background-color: #d8dbe0;
					border: 0;
					font-size: 20px;
					color: #20a8d8;
				}

				&-value {
					position: relative;
					display: block;
					font-size: 24px;
					line-height: 45px;
					text-align: center;
				}

				&-desc {
					position: absolute;
					top: 100%;
					left: 0;
					display: block;
					width: 100%;
					font-size: 12px;
					line-height: normal;
					text-align: center;
				}

				&-plus {
					position: absolute;
					top: 0;
					right: 0;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 45px;
					height: 45px;
					padding: 0;
					background-color: #d8dbe0;
					border: 0;
					font-size: 20px;
					color: #20a8d8;
				}
			}

			&-button {
				display: block;
				width: 100%;
			}

			&-links {
				span {
					&:after {
						content: "/";
					}

					&:last-child {
						&:after {
							display: none;
						}
					}
				}
			}
		}
	}

	&__data {
		padding: 5px 10px;

		&-item {
			margin-bottom: 40px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&-title {
			font-weight: 700;
			font-size: 14px;
			margin-bottom: 16px;
		}

		&-subtitle {
			font-weight: 700;
			font-size: 14px;
			line-height: 16px;
			color: #768192;
			margin-bottom: 8px;
		}

		&-value {
			display: block;
			font-weight: 700;
			font-size: 14px;
			line-height: 16px;

			&-desc {
				display: block;
				font-size: 11px;
				margin-bottom: 8px;
			}
		}

		&-date {
			font-size: 12px;
		}
	}

	&__bottom {
		border-top: 1px solid #c8ced3;
		padding: 5px 10px;
	}

	&__offer {
		&-title {
			margin-bottom: 12px;
			font-weight: 700;
			font-size: 14px;
		}

		&-input {
			margin-bottom: 0;
			padding-right: 10px;
		}

		&-button-container {
			padding-left: 10px;
			padding-right: 10px;
		}

	}
}

.VueTables__table {
	margin-bottom: 2rem;
}

.route-and-flight-auction-details {
	position: relative;
	margin-top: 24px;

	&__container {
		position: relative;

		&.custom-fieldset {
			margin-bottom: 0;
			padding-bottom: 16px;
		}

		&:after {
			position: absolute;
			top: 0;
			right: -15px;
			transform: translateX(-50%);
			display: block;
			width: 1px;
			height: 100%;
			background-color: #c8ced3;
			content: "";
		}
	}

	&__dates {
		margin-top: 32px;
		padding-top: 20px;
		border-top: 1px solid #c8ced3;

		@media screen and (max-width: 1199px) {
			position: relative;
			margin-top: 40px;
			padding-top: 20px;

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 100%;
				border: 1px solid #c8ced3;
				content: "";
			}
		}
	}

	&__title {
		margin-top: 0;
		margin-bottom: 8px;
	}

	&__route-container {
		position: relative;
		padding-top: 61px;
		padding-bottom: 37px;
		height: 100%;

		&-title {
			position: absolute;
			top: 0;
		}

		&-desc {
			position: absolute;
			top: 24px;
		}

		&-total {
			position: absolute;
			margin-top: 0.5rem;
		}

		&-second {
			position: absolute;
			margin-top: 2.125rem;
		}
	}

	&__date {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 32px;

		&-title {
			flex: 0 0 100%;
			max-width: 100%;
		}

		&-number {
			flex: 0 0 50%;
			max-width: 50%;
			padding-right: 15px;
		}

		&-time {
			flex: 0 0 50%;
			max-width: 50%;
			padding-left: 15px;
		}
	}

	&__transport {
		margin-top: 32px;
		padding-top: 20px;
		border-top: 1px dashed #c8ced3;
	}

	&__form-group {
		&:last-child {
			margin-bottom: 0;
		}

		.col-form-label {
			font-weight: bold;
		}
	}
}

.route-auction {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-width: 480px;
	height: 100%;

	&:before {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		width: 3px;
		height: 100%;
		background-color: #c8ced3;
		content: "";
	}

	&__item {
		position: relative;
		z-index: 1;
		max-width: 480px;
		padding: 10px 6px;
		margin-top: 18px;
		background: #f3f4f5;
		border: 1px solid #c8ced3;

		&:first-child {
			margin-top: 0;

			&:before {
				display: none;
			}
		}

		&:before {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			border: 10px solid transparent;
			border-top: 10px solid #c8ced3;
			content: "";
		}

		&-header {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			@media screen and (max-width: 767px) {
				flex-wrap: wrap;
			}
		}

		&-number {
			padding-right: 20px;
			white-space: nowrap;
		}

		&-type {
			width: auto;
			min-width: 136px;
			padding: 0 5px;
		}

		&-distance {
			padding-left: 20px;
			white-space: nowrap;
			@media screen and (max-width: 767px) {
				width: 100%;
				padding-left: 0;
			}
		}

		&-address {
			margin-top: 12px;
			margin-bottom: 0;
		}

		&-footer {
			padding-top: 28px;
			display: flex;
			justify-content: space-between;
		}
	}
}

.terms-and-participants {
	&__contractors {
		padding: 1rem;
	}

	&__start-price {
		position: relative;
		height: 100%;
		padding: 1rem;

		&:before {
			position: absolute;
			top: 0;
			left: -0.5px;
			display: block;
			width: 1px;
			height: 100%;
			background-color: #c8ced3;
			content: "";
			@media screen and (max-width: 991px) {
				left: 0;
				width: 100%;
				height: 1px;
			}
		}
	}

	&__auction-duration {
		position: relative;
		margin-top: 32px;
		padding-top: 20px;

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			border: 1px dashed #c8ced3;
			content: "";
		}
	}
}

.tab-content {
	.tab-pane.terms-and-participants {
		padding: 0;
	}
}
</style>
